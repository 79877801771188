import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// In your App.tsx
import './App.css';
import RegisterPage from './components/register/RegisterPage';
import LoginPage from './components/login/LoginPage';
import AccountPage from './components/account/AccountPage';
import HomePage from './components/home/HomePage';
import ItemPage from './components/item/ItemPage';
import DashBoardPage from './components/dashboard/DashBoardPage';
import ConstructionPage from './components/misc/ConstructionPage';
import { AuthProvider } from './utils/AuthenticationContext';
import { BrandProvider } from './utils/BrandContext';
import ShopifyLinkedPage from './components/misc/ShopifyLinkedPage';

const App: React.FC = () => {

  return (
    <div className="App">
      <BrandProvider>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<RegisterPage />} />
              {/* <Route path="/login" element={<LoginPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/dashboard/:section/:id?" element={<DashBoardPage />} />
              <Route path="/dashboard/:section?" element={<DashBoardPage />} />
              <Route path="/item/:id?" element={<ItemPage />} />
              <Route path="/item/:id/:section?" element={<ItemPage />} /> */}
              <Route path="/shopify-linked" element={<ShopifyLinkedPage />} />
            </Routes>
          </Router>
        </AuthProvider>
      </BrandProvider>
    </div>
  );
};

export default App;
