import Cookies from 'js-cookie';

const authenticatedFetch = async (url: string, options: RequestInit = {}) => {
    // Add the access token to the request headers
    const accessToken = Cookies.get('accessToken');
    const isFormData = options.body instanceof FormData;
    const authOptions = {
        ...options,
        headers: {
            ...(!isFormData && { 'Content-Type': 'application/json' }), // Conditionally set Content-Type
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        },
    };

    // Make the fetch request with the modified headers
    const response = await fetch(url, authOptions);

    // Check if the access token has expired and refresh it if necessary
    if (response.status !== 200 && response.status === 401) {
        // Refresh the access token
        const refreshToken = Cookies.get('refreshToken');
        const refreshResponse = await fetch(process.env.REACT_APP_SERVER_IP + 'api/v1/auth/token/refresh/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                access_token: "",
                refresh_token: refreshToken,
                token_type: "",
            }),
        });

        if (refreshResponse.ok) {
            const refreshData = await refreshResponse.json();
            Cookies.set('accessToken', refreshData.access_token, { secure: true, sameSite: 'strict' });

            // Retry the fetch request with the new access token
            authOptions.headers.Authorization = `Bearer ${refreshData.access_token}`;
            return await fetch(url, authOptions);
        } else {
            return null;
        }
    }
    return response;
};

export default authenticatedFetch;
