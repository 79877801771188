import React from 'react';

// Define User type
type Brand = {
    link: string;
    name: string;
    email: string;
    tone: string;
}

// Create the User Context
type BrandContextType = {
    brand: Brand | null;
    setBrand: React.Dispatch<React.SetStateAction<Brand | null>>;
};

export const BrandContext = React.createContext<BrandContextType | null>(null);

export const useBrand = () => {
    const brandContext = React.useContext(BrandContext);
    if (!brandContext) {
        throw new Error('useBrand must be used within a BrandProvider');
    }
    return brandContext;
}

type BrandProviderProps = {
    children: React.ReactNode;
}

export const BrandProvider = ({ children }: BrandProviderProps) => {
    const [brand, setBrand] = React.useState<Brand | null>(null);

    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
};
