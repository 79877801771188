// components/RegisterPage.tsx
import React from 'react';

import BaseHeader from '../headers/base/Header';
import AuthenticatedHeader from '../headers/authenticated/Header';
import './ShopifyLinkedPage.css';
import { useAuth } from '../../utils/AuthenticationContext';

const ShopifyLinkedPage: React.FC = () => {
    const { isLoading, isAuthenticated } = useAuth();

    return (
        <div className='shopify-linked'>
            {isAuthenticated && !isLoading ? <AuthenticatedHeader /> : <BaseHeader />}
            <div className='shopify-linked-content'>
                <h1>Your Shopify store has been successfully linked to your account.</h1>
            </div>
        </div>
    );
};

export default ShopifyLinkedPage;