// src/components/Header/index.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg'
import './Header.css';
import '../base/Header.css';
import MenuPopUp from './MenuPopUp';
import { useAuth } from '../../../utils/AuthenticationContext';
import HeaderButton from '../base/HeaderButton';


const AuthenticatedHeader: React.FC = () => {
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const { logout } = useAuth();

    const handleIconClick = () => {
        setPopupVisible(!isPopupVisible);
    };

    const handleRedirectHome = () => {
        navigate('/dashboard');
    };

    const handleRedirectAccount = () => {
        navigate('/account');
    };

    const handleRedirectLogout = () => {
        logout();
        navigate('/');
    };


    return (
        <header className="authenticated-header">
            <div className="authenticated-header-brand">
                <HeaderButton name="FORM" customStyles={{ button: { fontFamily: "eckmannpsych-small" } }} redirect={handleRedirectHome} />
            </div>
            <div className="divider" />
            <div className="authenticated-account" onClick={handleIconClick}><CgProfile className="authenticated-header-icon" size={40} /></div>
            {isPopupVisible && (
                <MenuPopUp onAccountClick={handleRedirectAccount} onLogoutClick={handleRedirectLogout} />
            )}
        </header >
    );
};

export default AuthenticatedHeader;