import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import authenticatedFetch from './api';
import { BrandContext } from './BrandContext';

interface AuthContextType {
    isAuthenticated: boolean;
    isLoading: boolean;
    login: (stayLoggedIn: boolean, access_token: string, refresh_token: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

type AuthenticationProviderProps = {
    children: React.ReactNode;
}
export const AuthProvider = ({ children }: AuthenticationProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // New loading state
    const brandContext = useContext(BrandContext);

    const fetchData = async () => {
        const response = await authenticatedFetch(process.env.REACT_APP_SERVER_IP + 'api/v1/brands/me/');
        if (response && response.ok) {
            const brandData = await response.json();
            brandContext?.setBrand(brandData);
            setIsAuthenticated(true);
            setIsLoading(false);
            return;
        }
        setIsAuthenticated(false);
        setIsLoading(false);
    };

    useEffect(() => {
        const token = Cookies.get('accessToken');
        if (token === "" || token === undefined) {
            setIsAuthenticated(false);
            setIsLoading(false);
            return;
        }

        fetchData();
    }, []);

    const login = (stayLoggedIn: boolean, access_token: string, refresh_token: string) => {
        Cookies.set('accessToken', access_token, { secure: true, sameSite: 'strict' });
        if (stayLoggedIn) {
            Cookies.set('refreshToken', refresh_token, { secure: true, sameSite: 'strict' });
        }
        setIsAuthenticated(true);
        fetchData();
    }

    const logout = () => {
        setIsAuthenticated(false);
        Cookies.set('accessToken', "", { secure: true, sameSite: 'strict' });
        Cookies.set('refreshToken', "", { secure: true, sameSite: 'strict' });
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
