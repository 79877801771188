// src/components/Popup/index.tsx

import React from 'react';
import './MenuPopUp.css';

interface PopupProps {
    onAccountClick: () => void;
    onLogoutClick: () => void;
}

const MenuPopup: React.FC<PopupProps> = ({ onAccountClick, onLogoutClick }) => {
    return (
        <div className="popup">
            <button onClick={onAccountClick}>Account</button>
            <button onClick={onLogoutClick}>Logout</button>
        </div>
    );
};

export default MenuPopup;