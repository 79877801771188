// Header.tsx
import React, { useState } from 'react';
import './Header.css';
import HeaderButton from './HeaderButton';
import { useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";


const BaseHeader: React.FC = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleRedirectLogin = () => {
        navigate('/login');
        setIsMenuOpen(false);
    };
    const handleRedirectRegister = () => {
        navigate('/register');
        setIsMenuOpen(false);
    };

    const handleRedirectHome = () => {
        navigate('/');
        setIsMenuOpen(false);
    };

    const handleRedirectDescription = () => {
        navigate('/#about');
        setIsMenuOpen(false);
    };

    const handleRedirectFeatures = () => {
        navigate('/#features');
        setIsMenuOpen(false);
    };

    const handleRedirectContact = () => {
        navigate('/#contact');
        setIsMenuOpen(false);
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="home-header">
            <div className="header-left-side">
                <HeaderButton name="About" redirect={handleRedirectDescription} />
                <HeaderButton name="How it Works" redirect={handleRedirectFeatures} />
                <HeaderButton name="Contact" redirect={handleRedirectContact} />
            </div>

            <div className="home-header-brand">
                <HeaderButton name="FORM" customStyles={{ button: { fontFamily: "eckmannpsych-small" } }} redirect={handleRedirectHome} />
            </div>

            <div className="header-right-side">
                {/* <HeaderButton name="Login" redirect={handleRedirectLogin} /> */}
                <HeaderButton name="Request a Demo" redirect={handleRedirectRegister} />
            </div>

            {/* Mobile */}
            <div className="home-header-brand-mobile">
                <HeaderButton name="FORM" customStyles={{ button: { fontFamily: "eckmannpsych-small" } }} redirect={handleRedirectHome} />
            </div>

            <div className="hamburger-menu" onClick={toggleMenu}>
                <GiHamburgerMenu />
            </div>
            {isMenuOpen && (
                <div className="mobile-menu">
                    <HeaderButton name="About" redirect={handleRedirectHome} />
                    <HeaderButton name="How it Works" redirect={handleRedirectHome} />
                    <HeaderButton name="Contact" redirect={handleRedirectHome} />
                    {/* <HeaderButton name="Login" redirect={handleRedirectLogin} /> */}
                    <HeaderButton name="Request a Demo" redirect={handleRedirectRegister} />
                </div>
            )}
        </header>

    );
};




export default BaseHeader;
