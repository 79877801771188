import React, { useState, ChangeEvent, FormEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Country, getData } from 'country-list';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import './RegisterPage.css';
import BaseHeader from '../headers/base/Header';
import { useAuth } from '../../utils/AuthenticationContext';

interface CountryOption {
    label: string;
    value: string;
}

const RegisterPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('United Kingdom');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [link, setLink] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { logout } = useAuth();
    const [countriesData, setCountriesData] = useState<CountryOption[]>([]);
    const [captchaValue, setCaptchaValue] = useState<string | null>(null);
    const recaptchaRef = useRef<any>(null);

    const onCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };

    useEffect(() => {
        logout()

        const newCountriesData = getData().map((country: Country) => ({
            label: country.name,
            value: country.code
        })).sort((a: CountryOption, b: CountryOption) => a.label.localeCompare(b.label));
        setCountriesData(newCountriesData);
        setLocation(newCountriesData[0].label);
    }, []);

    function handleCountrySelect(selectedOption: string) {
        setLocation(selectedOption || '');
    }

    // The rest of your component will go here
    const validateForm = () => {

        // Check if all required variables are provided
        var missing_variables = '';
        if (!name) {
            missing_variables += 'Please provide a correct name.\n';
        }
        if (!email) {
            missing_variables += 'Please provide a correct email.\n';
        }
        if (!phoneNumber) {
            missing_variables += 'Please provide a valid phone number\n'
        }
        if (!location) {
            missing_variables += 'Please provide the country this brand is based.\n';
        }
        if (!link) {
            missing_variables += 'Please provide a correct password.\n';
        }
        if (!email || !link || !name || !phoneNumber || !location) {
            setError(missing_variables);
            return;
        }

        // Check if the password and confirm password fields match
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setError("Please enter a valid email address.");
            return false;
        }

        // validate link
        const linkRegex = /^((http|https):\/\/)?(www.)?[a-z0-9]+\.[a-z.]+(\/[a-zA-Z0-9#]+\/?)*$/;
        if (!linkRegex.test(link)) {
            setError("Please enter a valid website.");
            return false;
        }

        // validate country in country list
        const countryList = getData();
        const country = countryList.find((country: Country) => country.name === location);
        if (!country) {
            setError("Please enter a valid country.");
            return false;
        }

        // validate captcha
        if (!captchaValue) {
            setError("Please complete the captcha.");
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: FormEvent) => {
        setError('');
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_SERVER_IP + 'api/v1/brands/register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone_number: phoneNumber,
                    location: location,
                    link: link,
                    captcha: captchaValue
                }),
            });

            if (response) {
                const responseData = await response.json();
                if (response.ok) {
                    // Registration was successful, navigate to the login screen
                    // navigate('/login', { state: { registrationSuccess: true } });
                    setSuccess('Registration successful. We will contact you as soon as possible.');
                    setError('');
                } else {
                    // Extract error message from the server response
                    const errorMessage = Object.values(responseData).flat().join('\n') || 'An error occurred during registration. Please try again later.';
                    recaptchaRef.current.reset();
                    setCaptchaValue(null);
                    // Display an error message on the registration screen
                    setError(errorMessage);
                }
            } else {
                setError('An error occurred during registration. Please try again later.');
            }
        } catch (error) {
            // Display an error message on the registration screen
            setError('An error occurred during registration. Please try again later.');
        }
    };

    return (
        <div className='register'>
            <BaseHeader />
            <div className="content">
                <div className="register-form-container">
                    <form onSubmit={handleSubmit} className="register-form">
                        <h2 className="register-title">Register</h2>
                        <label htmlFor="Name">Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                            className="register-input-new"
                        />
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            className="register-input-new"
                        />
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <PhoneInput
                            containerClass="register-phone-input"
                            inputClass="register-phone-input"
                            buttonClass="register-phone-input"
                            dropdownClass="register-phone-input"
                            searchClass="register-phone-input"
                            country={'gb'}
                            onChange={phone => setPhoneNumber(phone)}
                        />
                        <label htmlFor="location">Location:</label>
                        <select className="register-select" value={location}
                            onChange={(e) => handleCountrySelect(e.target.value)}>
                            {countriesData.map((country: CountryOption) => (
                                <option key={country.label} value={country.label}>{country.label}</option>
                            ))}
                        </select>
                        <label htmlFor="website">Website:</label>
                        <input
                            type="text"
                            value={link}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
                            className="register-input-new"
                        />
                        <div className='register-captcha'>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""}
                                onChange={onCaptchaChange}
                            />
                        </div>
                        <button type="submit" className="register-button">Register</button>
                    </form>
                    <div className="error-message">
                        {error && error.split("\n").map((err: string, index: number) => err && <p key={index}>{err}</p>)}
                    </div>
                    <div className="success-message">
                        {success && success.split("\n").map((succ: string, index: number) => succ && <p key={index}>{succ}</p>)}
                    </div>
                </div>
            </div >
        </div>
    );
};

export default RegisterPage;
