import React from 'react';
import './HeaderButton.css';

interface CustomStyles {
  [key: string]: React.CSSProperties;
}

interface HeaderButtonProps {
  name: string;
  redirect: () => void;
  customStyles?: CustomStyles;
}


const HeaderButton: React.FC<HeaderButtonProps> = ({ name, redirect, customStyles }) => {
  return (
    <button style={{ ...customStyles?.button }} className="app-button" onClick={redirect}>
      {name}
    </button>
  );
};

export default HeaderButton;