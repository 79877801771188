import React, { useRef, useEffect, useState } from 'react';
import './Content.css';
import videoMobile from "../../assets/videos/video-mobile.mp4";
import videoStandard from "../../assets/videos/video-standard.mp4";
import { useLocation } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Content: React.FC = () => {
    const descriptionRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    // State for tracking window width
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set mobile view state based on window width
            setIsMobileView(window.innerWidth < 768);
        }

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (location.hash === '#about' && descriptionRef.current) {
            descriptionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (location.hash === '#features' && featuresRef.current) {
            featuresRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (location.hash === '#contact' && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]); // React to changes in location (hash)


    const sliderSettings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const featuresContent = (
        <React.Fragment>
            <div className="home-feature">
                <h3 className="home-feature-title">FIT vs SIZING TOOL</h3>
                <p className="home-feature-description">FORM differentiates from other products on the market through an enhanced customer experience. Generating not only a size recommendation but personalized fit descriptions for every customer.</p>
            </div>
            <div className="home-feature">
                <h3 className="home-feature-title">LOW FRICTION</h3>
                <p className="home-feature-description">Requiring minimal customer input, FORM offers a low friction solution with highly effective results. Giving the customer a better insight into how a garment will fit before they make a purchase.</p>
            </div>
            <div className="home-feature">
                <h3 className="home-feature-title">TAILORED AI</h3>
                <p className="home-feature-description">Each customer experience will benefit the next. Through machine learning FORM will improve from each engagement, building a universally consistent approach to your fit and styling.</p>
            </div>
        </React.Fragment>
    );

    return (
        <div className="content">
            <div className="home-video-container">
                <video className="home-video-standard" src={videoStandard} autoPlay loop muted playsInline></video>
                <video className="home-video-mobile" src={videoMobile} autoPlay loop muted playsInline></video>
                <div className="home-slogan">
                    <div className="home-slogan-main">FORM DEFINES FIT</div>
                    <div className="home-slogan-sub">A personalized fit for each unique form.</div>
                </div>
            </div>
            <div className="home-info-section">
                <div className="home-info-description-title" ref={descriptionRef}>
                    <h2 className="home-info-title">FIT & STYLING TOOL POWERED BY AI</h2>
                    <p className="home-info-description">We’re creating a universal approach to fit and styling for fashion e-commerce. Our customer-led solution will remove the barriers when shopping online to ensure customer satisfaction and increased loyalty.</p>
                    <div className="home-register-container">
                        <button className="home-register-button">Request a Demo</button>
                    </div>
                </div>
                <div className="home-info-features" ref={featuresRef}>
                    {isMobileView ? (
                        <Slider {...sliderSettings}>
                            <div className="home-feature">
                                <h3 className="home-feature-title">FIT vs SIZING TOOL</h3>
                                <p className="home-feature-description">FORM differentiates from other products on the market through an enhanced customer experience. Generating not only a size recommendation but personalized fit descriptions for every customer.</p>
                            </div>
                            <div className="home-feature">
                                <h3 className="home-feature-title">LOW FRICTION</h3>
                                <p className="home-feature-description">Requiring minimal customer input, FORM offers a low friction solution with highly effective results. Giving the customer a better insight into how a garment will fit before they make a purchase.</p>
                            </div>
                            <div className="home-feature">
                                <h3 className="home-feature-title">TAILORED AI</h3>
                                <p className="home-feature-description">Each customer experience will benefit the next. Through machine learning FORM will improve from each engagement, building a universally consistent approach to your fit and styling.</p>
                            </div>
                        </Slider>
                    ) : (
                        featuresContent
                    )}
                </div>
                <div className="home-register-socials" ref={contactRef}>
                    <div className="home-press-enquiries">
                        <span>PRESS ENQUIRIES</span>
                        <span><a href="mailto:socials@fitwithform.com">socials@fitwithform.com</a></span>
                        <span><a href="https://www.instagram.com/fitwithformai?igsh=MXQ3aXo1MXV2b205dA==" className="social-link"><FaInstagram size={20} /></a></span>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
