// components/RegisterPage.tsx
import React from 'react';

import BaseHeader from '../headers/base/Header';
import AuthenticatedHeader from '../headers/authenticated/Header';
import Content from './Content';
import './HomePage.css';
import { useAuth } from '../../utils/AuthenticationContext';

const HomePage: React.FC = () => {
    const { isLoading, isAuthenticated } = useAuth();

    return (
        <div className='home'>
            {isAuthenticated && !isLoading ? <AuthenticatedHeader /> : <BaseHeader />}
            <Content />
        </div>
    );
};

export default HomePage;